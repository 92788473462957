import { useState } from 'react';
import { Button, Pagination, Switch, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { size as length, includes, isEmpty, isEqual, join } from 'lodash';

import { grid_columns_map } from './grid-configs';
import { EditActionCell, SelectAllButton } from '@med-fe/ui';
import styles from './BaseGrid.module.scss';
import {
  getDoctorsForGrid,
  getDoctor,
  getDoctorsWithSpecialRanges,
  getODDataIncomplete,
  getSelectedRow,
  getIsHooChangedAction,
} from '../../pace/pace-selectors';
import { fetchSelectedDoctor, setSelectedRow } from '../../pace/pace-actions';
import { getFirstDayOfCurrentMonth } from '../../common/helpers/date';

import EmployeePattern from './components/employee-pattern/EmployeePattern';
import { getImportValidationData, getTemplates } from '../templates/templates-selector';
import { useGridSelectionBulk } from './hooks/use-grid-selection-bulk';
import { SpecialRangesEditBtn } from './components/SpecialRangesEditBtn';
import useToggleDrawerVisibility from './hooks/use-toggle-drawer-visibility';
import SpecialRangesDetails from './SpecialRangesDetails';
import { SpecialRangesBulkUpdateDrawer } from './components/SpecialRangesBulkUpdateDrawer';
import { getAuditForGrid } from '../audit/audit-reducer';
import { TemplatesActions } from '../templates/TemplatesActions';
import { getNewOfficesForGrid } from '../../pace/new-offices/new-offices-selectors';
import { SlotBulkUpdateDrawer } from './components/SlotBulkUpdateDrawer';
import styled from 'styled-components';
import { UnmappedGridActions } from './components/UnmappedGridActions';
import { getException } from '../ExceptiopnManagement/selectors';
import menuStyles from '../navbar/navbarMenu.module.scss';
import ExceptionDetails from '../ExceptiopnManagement/ExceptionDetails';
import moment from 'moment';
import { exceptionColumn } from './grid-configs/unmappedOds';

interface IDoctorInfo {
  employeeId: string;
  name: string;
  office: string;
  officeName: string;
}

interface baseGridTypes {
  id: any;
  total: any;
  search: any;
  setSearch: any;
  loading: any;
  isPagination: any;
  rowKey: any;
  rowSelection: any;
  onlyTotalCount: any;
  setIsAllSelected: any;
  setIsSearch?: any;
  showException?: any;
  setShowException?: any;
}

function BaseGrid({
  id,
  total,
  search,
  setSearch,
  loading,
  isPagination,
  rowKey,
  rowSelection,
  onlyTotalCount,
  setIsAllSelected,
  setIsSearch,
  showException,
  setShowException,
}: baseGridTypes) {
  const selectorMap: any = {
    unmappedOds: getDoctorsForGrid,
    specialRanges: getDoctorsWithSpecialRanges,
    odDataIncomplete: getODDataIncomplete,
    templatesManagement: getTemplates,
    audit: getAuditForGrid,
    newOffices: getNewOfficesForGrid,
    templateRanges: getImportValidationData,
    assignTemplates: getImportValidationData,
    templateMaintenance: getImportValidationData,
    exceptionManagement: getException,
  };

  const dispatch = useDispatch();
  const data = useSelector((state) => selectorMap[id](state));

  const isUnmappedOdsTab = id === 'unmappedOds';
  const isSpecialRangesTab = id === 'specialRanges';
  const isTemplatesManagementPage = id === 'templatesManagement';
  const isExceptionManagementPage = id === 'exceptionManagement';
  const { page, size, base } = search;
  const isNoSpecialRangesSubtab = isSpecialRangesTab && base === true;
  const selectedRow = useSelector(getSelectedRow);
  const doctor = useSelector(getDoctor);
  const isHooChangedAction = useSelector(getIsHooChangedAction);
  const [doctorInfo, setDoctorInfo] = useState<IDoctorInfo | null>(null);
  const [exceptionDrawer, setExceptionDrawer] = useState(false);
  const [selectedException, setSelectedException] = useState(null);
  const [rowDetail, setRowDetail] = useState<any>();
  const gridIdentifier =
    id === 'specialRanges'
      ? `specialRanges${base ? 'Base' : 'All'}`
      : isUnmappedOdsTab && isHooChangedAction
      ? `${id}HooChanged`
      : id;

  const { isDrawerVisible, openDrawer, closeDrawer } = useToggleDrawerVisibility();
  const { onRow, selectedKeys, selectedItems, setSelected, clearSelectedRows, isAllSelected, toggleIsAllSelected } =
    useGridSelectionBulk({ data, search, gridId: id });
  const isBulk =
    ((isAllSelected && length(selectedKeys) < total - 1) || (!isAllSelected && length(selectedKeys) > 1)) && base;
  const isSlotBulk = length(selectedKeys) > 0 && !base;
  if (isUnmappedOdsTab) {
    const found = grid_columns_map?.unmappedOds?.some((el) => el.title === 'Exception');
    if (!base) {
      if (!found) {
        grid_columns_map?.unmappedOds?.splice(1, 0, exceptionColumn);
      }
    } else {
      if (found) {
        grid_columns_map?.unmappedOds?.splice(1, 1);
      }
    }
  }
  if (isSpecialRangesTab) {
    const found = grid_columns_map?.specialRangesBase?.some((el) => el.title === 'Exception');
    if (!found && !showException) {
      grid_columns_map?.specialRangesBase?.splice(1, 0, exceptionColumn);
    } else if (found && showException) {
      grid_columns_map?.specialRangesBase?.splice(1, 1);
    }
    const allFound = grid_columns_map?.specialRangesAll?.some((el) => el.title === 'Exception');
    if (!allFound && !showException) {
      grid_columns_map?.specialRangesAll?.splice(1, 0, exceptionColumn);
    } else if (allFound && showException) {
      grid_columns_map?.specialRangesAll?.splice(1, 1);
    }
  }
  const columns = grid_columns_map[gridIdentifier].map((col: any) => {
    const columnConfig = {
      ...col,
      ellipsis: true,
    };
    if (col.key === 'doctor' || col.key === 'exceptionEmployeeName') {
      columnConfig['render'] = (text: string, record: any, rowIndex: any) => {
        const onClickHandler = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
          e.preventDefault();
          e.stopPropagation();

          setDoctorInfo({
            employeeId: record.employeeId,
            name: record.employeeName,
            office: record.office,
            officeName: record.officeName,
            ...(record.reason && { reason: record.reason }),
          });

          if (id === 'specialRanges') {
            clearSelectedRows();
            toggleIsAllSelected(false);
            openDrawer();
          } else {
            dispatch(
              fetchSelectedDoctor({
                id: record.employeeId,
                office: record.office,
                base,
                startDate: getFirstDayOfCurrentMonth(),
              })
            );
          }
          dispatch(setSelectedRow(rowIndex));
        };

        return <EditActionCell text={text} status={record.status} onClickHandler={onClickHandler} />;
      };
    }
    if (col.key === 'exceptionEmployeeName') {
      columnConfig['render'] = (text: string, record: any, rowIndex: any) => {
        const onClickHandler = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
          e.preventDefault();
          e.stopPropagation();
          setExceptionDrawer(true);
          setSelectedException(record.exceptionId);
          setRowDetail(record);
        };

        return <EditActionCell text={text} record={record} onClickHandler={onClickHandler} />;
      };
    }
    return columnConfig;
  });

  const handleTableChange = (pagination: any, filters: any, sorter: { field: any; order: any }) => {
    setSearch({ sorting: { field: sorter.field, order: sorter.order } });
  };

  const onNoSpecialRangeClick = () => {
    setSearch({ page: 0, base: true, path: 'unmapped' });
    clearSelectedRows();
    setIsAllSelected(false);
    toggleIsAllSelected(false);
  };

  const onAllClick = () => {
    setSearch({ page: 0, base: false, path: 'mapped' });
    clearSelectedRows();
    setIsAllSelected(true);
    toggleIsAllSelected(false);
  };

  return (
    <>
      <div className={styles['table-container']}>
        <div className={styles['table-wrapper']}>
          <Table
            rowKey={rowKey}
            className={styles['table-common']}
            rowClassName={(record, i) =>
              ((isSpecialRangesTab || isTemplatesManagementPage) &&
                ((!isAllSelected && includes(selectedKeys, record.key)) ||
                  (isAllSelected && !includes(selectedKeys, record.key)))) ||
              isEqual(i, selectedRow)
                ? 'table-selected-row'
                : isSpecialRangesTab || isUnmappedOdsTab
                ? record.exceptionData
                  ? menuStyles['red']
                  : ''
                : isExceptionManagementPage
                ? record?.endDate && moment(record?.endDate).isBefore(moment())
                  ? menuStyles['grey']
                  : ''
                : ''
            }
            onChange={isTemplatesManagementPage || isExceptionManagementPage ? handleTableChange : undefined}
            dataSource={data}
            columns={columns}
            pagination={false}
            loading={!isEmpty(doctor) ? false : loading}
            scroll={{ x: '100%', y: 'calc(100vh - 17.5em)' }}
            onRow={isSpecialRangesTab || isTemplatesManagementPage ? onRow : undefined}
            rowSelection={rowSelection}
          />

          {isSpecialRangesTab && (isBulk || isSlotBulk) && <SpecialRangesEditBtn onClick={openDrawer} />}
          {id === 'exceptionManagement' ? (
            <Button
              className={`${styles['add-btn']} ${true ? '' : styles['disabled']}`}
              onClick={(e) => {
                e.preventDefault();
                setExceptionDrawer(true);
              }}
            >
              <img src={'./icons/action-add.svg'} alt='add' />
            </Button>
          ) : (
            ''
          )}
        </div>
        {onlyTotalCount && (
          <>
            <div className={styles['total-count-container']}>
              <div style={{ display: 'flex' }} className='wrapper'>
                <div className={styles['total-container']}>
                  <div className={styles['total-title']}>TOTAL ENTRIES</div>
                  <div className={styles['total-count']}>
                    <span>{total}</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {!isPagination && (
          <div className={styles['pagination-container']}>
            <Pagination
              current={page + 1}
              defaultPageSize={size}
              total={total}
              showTotal={(total: any) =>
                id !== 'audit' && (
                  <div style={{ display: 'flex' }} className='wrapper'>
                    <div className={styles['total-container']}>
                      {!isEmpty(selectedKeys) ? (
                        <>
                          <div className={styles['total-title']}>
                            <div className={styles['total-selected-rows']}>
                              <span>{isAllSelected ? 'EXCLUDED' : 'SELECTED'}</span>
                              <span onClick={() => setSelected([])}>
                                <img src={'./icons/action-close.svg'} alt='close' />
                              </span>
                            </div>
                          </div>
                          <div className={styles['total-count']}>
                            <span>{join([length(selectedKeys), isAllSelected ? `/${total}` : ''], '')}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={styles['total-title']}>TOTAL ENTRIES</div>
                          <div className={styles['total-count']}>
                            <span>{total}</span>
                          </div>
                        </>
                      )}
                    </div>
                    {isEqual(id, 'unmappedOds') && <UnmappedGridActions setSearch={setSearch} />}
                    {isEqual(id, 'specialRanges') && (
                      <>
                        <Button
                          type={base ? 'primary' : 'default'}
                          htmlType='submit'
                          className={`button ${base ? '' : 'ant-btn-default'}`}
                          style={{ marginLeft: 20 }}
                          onClick={onNoSpecialRangeClick}
                        >
                          NO SPECIAL RANGE
                        </Button>
                        <Button
                          type={base ? 'default' : 'primary'}
                          htmlType='submit'
                          className={` ${base ? 'ant-btn-default' : ''}`}
                          onClick={onAllClick}
                        >
                          ALL
                        </Button>
                      </>
                    )}
                  </div>
                )
              }
              onChange={(pageNumber: number, pageSize: any) => {
                setSearch({ size: pageSize, page: size !== pageSize ? 0 : pageNumber - 1 });
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                isSpecialRangesTab ? setIsSearch(true) : '';
              }}
            />
            {isTemplatesManagementPage && (
              <TemplatesActions selectedKeys={selectedKeys} data={data} clearSelectedRows={clearSelectedRows} />
            )}
            {isSpecialRangesTab && (
              <div className='archived-switch'>
                <label className='label-text'>{'Hide Exceptions'}</label>
                <Switch
                  size='small'
                  checked={showException}
                  onChange={(flag) => {
                    setShowException(flag);
                  }}
                  style={{ marginLeft: '5px' }}
                />
                {/* </Label> */}
              </div>
            )}
            {isNoSpecialRangesSubtab && (
              <WrapSelectAllButton>
                <SelectAllButton
                  whatSelect='doctors'
                  isAllSelected={isAllSelected}
                  onClick={() => {
                    toggleIsAllSelected();
                    clearSelectedRows();
                  }}
                />
              </WrapSelectAllButton>
            )}
            <img alt='logo' className={styles['logo']} src={'./images/logo.svg'} />
          </div>
        )}
      </div>

      {isUnmappedOdsTab && !isEmpty(doctor) && (
        <EmployeePattern doctor={doctor} doctorInfo={doctorInfo} search={search} />
      )}
      {isSpecialRangesTab && !isBulk && !isSlotBulk && isDrawerVisible && (
        <SpecialRangesDetails
          search={search}
          isVisible={isDrawerVisible}
          doctorInfo={doctorInfo}
          onClose={closeDrawer}
        />
      )}
      {isSpecialRangesTab && isBulk && isDrawerVisible && (
        <SpecialRangesBulkUpdateDrawer
          onClose={closeDrawer}
          clearSelectedRows={clearSelectedRows}
          selectedKeys={selectedKeys}
          selectedItems={selectedItems}
          isAllSelected={isAllSelected}
          total={total}
          isDrawerVisible={isDrawerVisible}
        />
      )}
      {isSpecialRangesTab && isSlotBulk && isDrawerVisible && (
        <SlotBulkUpdateDrawer onClose={closeDrawer} selectedItems={selectedItems} isDrawerVisible={isDrawerVisible} />
      )}
      <ExceptionDetails
        id={id}
        excepId={selectedException}
        record={rowDetail}
        setRowDetail={setRowDetail}
        setSelectedException={setSelectedException}
        setExceptionDrawer={setExceptionDrawer}
        exceptionDrawer={exceptionDrawer}
        hideDetails={() => {
          setSelectedException(null);
          setExceptionDrawer(false);
          setRowDetail({});
        }}
      />
    </>
  );
}

export default BaseGrid;

const WrapSelectAllButton = styled.div`
  display: flex;
  flex-grow: 1;
`;
