import React, { useEffect, useState, useMemo } from 'react';
import { Collapse, Empty, Spin, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssignInProgress,
  getIsDoctorLoading,
  getSearchDoctors,
  getSelectedDoctorsSpecial,
  getSelectedTemplates,
  getSpecialRangesReasonsSearch,
  getSpecialRangesSearch,
  getSuccessAssign,
} from '../../../pace/pace-selectors';
import { filter, fromPairs, isEmpty, isEqual, isNil, pick, reduce, some, toPairs, size as length } from 'lodash';
import {
  getIsSameMonth,
  getIsSameOrLaterThanToday,
  getMonthRangeValues,
  getRemainingWeeksInMonth,
  getMomentDate,
  get12MonthEndDate,
} from '../../../common/helpers/date';
import {
  bulkAssignTemplateSpecial,
  fetchAllTemplates,
  fetchDoctorsSpecial,
  fetchSelectedDoctorsSpecial,
  fetchSelectedDoctorsSpecialSuccess,
  clearAllDoctorsTemplates,
  setInitialSelectedTemplates,
  removeAllDoctorsTemplates,
  fetchSelectedDoctorsSpecialAll,
} from '../../../pace/pace-actions';
import {
  StyledAssignButton,
  StyledCalendar,
  StyledResetButton,
  StyledEmpty,
  StyledDrawer,
  StyledMonthSelect,
  StyledWeekWrapper,
  StyledWarningIcon,
  stylesMonthSelect,
  StyledRangePicker,
} from './styled/styled';
import WeekSingleOfficeGrid from './special-ranges/WeekSingleOfficeGrid';
import { useDeepEffect } from '../../../common/hooks/use-deep-effect';
const { Panel } = Collapse;

interface specialRangesTemplateDto {
  date: 'string';
  description: 'string';
  templateId: 0;
}
interface bulkAssignTemplateDto {
  specialRangesTemplateDto: specialRangesTemplateDto[];
  userOfficeDtoList: {
    employeeId: 'string';
    office: 'string';
  };
}

export const SpecialRangesBulkUpdateDrawer = ({
  onClose,
  selectedKeys,
  selectedItems,
  isAllSelected,
  total,
  isDrawerVisible,
  clearSelectedRows,
}) => {
  const dispatch = useDispatch();
  const assignInProgress = useSelector(getAssignInProgress);
  const successAssign = useSelector(getSuccessAssign);
  const search = useSelector(getSearchDoctors);
  const { startDate } = useSelector(getSpecialRangesSearch);
  const data = useSelector((state) => getSelectedDoctorsSpecial(state));
  const [assignedTemplates, setAssignedTemplates] = useState({});
  const selectedTemplates = useSelector(getSelectedTemplates);
  const doctorLoading = useSelector(getIsDoctorLoading);
  const specialRangesSearch = useSelector(getSpecialRangesSearch);
  const specialRangesReasonsSearch = useSelector(getSpecialRangesReasonsSearch);
  const monthRanges = useMemo(() => getMonthRangeValues(), []);
  const [preApplyDateRange, setPreApplyDateRange] = useState<[string, string]>([
    specialRangesSearch?.dateFrom ?? '',
    specialRangesSearch?.dateTo ?? '',
  ]);
  const [dateRange, setDateRange] = useState<[string, string]>([
    specialRangesSearch?.dateFrom ?? '',
    specialRangesSearch?.dateTo ?? '',
  ]);
  const filteredMonthRanges = useMemo(
    () =>
      dateRange[0] && dateRange[1]
        ? monthRanges.filter(({ value }) => getIsSameMonth(dateRange[0], value) || getIsSameMonth(dateRange[1], value))
        : monthRanges,
    [dateRange]
  );
  const [rangeValue, setRangeValue]: any = useState(filteredMonthRanges[0]);
  const [warning, setWarning] = useState({});

  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);
  const dataFilteredByMonth = useMemo(
    () =>
      data?.map(({ weeks, ...rest }) => ({
        weeks: weeks.filter(
          ({ 0: start, 6: end }) =>
            getIsSameMonth(rangeValue.value, start.date) || getIsSameMonth(rangeValue.value, end.date)
        ),
        ...rest,
      })),
    [data]
  );

  useEffect(() => {
    setRangeValue(filteredMonthRanges[0]);
  }, [filteredMonthRanges]);

  useEffect(() => {
    const fetchFn = isAllSelected ? fetchSelectedDoctorsSpecialAll : fetchSelectedDoctorsSpecial;
    const searchPayload = fromPairs(
      filter(
        toPairs(
          pick(specialRangesSearch, [
            'employeeName',
            'employeeId',
            'office',
            'dateFrom',
            'dateTo',
            'odShiftLengthFrom',
            'odShiftLengthTo',
            'officeLengthHOOFrom',
            'officeLengthHOOTo',
          ])
        ),
        ([_, value]) => !isNil(value)
      )
    );
    const additionalPayload = isAllSelected
      ? { ...searchPayload, ...specialRangesReasonsSearch, unselectedEmployees: selectedItems }
      : { selectedItems };
    let commonPayload = {};

    if (dateRange[0] && dateRange[1]) {
      commonPayload = {
        startDate: dateRange[0],
        endDate: dateRange[1],
        showSuggestedTemplates: true,
      };
    } else {
      commonPayload = {
        startDate: monthRanges[0].value,
        endDate: get12MonthEndDate(monthRanges[0].value),
        showSuggestedTemplates: false,
      };
    }

    dispatch(
      fetchFn({
        ...commonPayload,
        ...additionalPayload,
      })
    );
    setWarning({});
    setIsApplyButtonDisabled(true);
  }, [dateRange]);

  useEffect(() => {
    dispatch(fetchAllTemplates());
  }, []);

  const processTemplates = (doctor, withSuggested) => {
    const schedule = doctor?.schedule;
    const getSuggested = (dayIdx) => schedule?.[dayIdx]?.suggestedTemplate;
    const suggestedTemplate = (suggested, date) => {
      if (getIsSameMonth(rangeValue?.value ?? startDate, date) && suggested) {
        const isWarning = suggested?.cantFindTemplate || suggested?.removedNL;

        setWarning((prev) => ({
          ...prev,
          ...(isWarning && { [`${doctor.employeeId}_${doctor.office}`]: true }),
        }));

        return {
          [date]: {
            key: suggested?.id?.toString(),
            value: suggested?.id,
            children: suggested?.templateName,
            description: suggested?.templateName,
            suggested: true,
            cantFindTemplate: suggested?.cantFindTemplate,
            removedNL: suggested?.removedNL,
            specialRangesReasons: suggested?.specialRangesReasons,
          },
        };
      } else {
        return {};
      }
    };
    return reduce(
      schedule,
      (acc, it, idx) => {
        const suggested = getIsSameOrLaterThanToday(it?.date) && withSuggested ? getSuggested(idx) : null;
        return {
          ...acc,
          ...(it?.specialRangeTemplateId
            ? {
                [it?.date]: {
                  key: it?.specialRangeTemplateId.toString(),
                  value: it?.specialRangeTemplateId,
                  children: it?.specialRangeTemplateName,
                  description: it?.specialRangeDescription ? it?.specialRangeDescription : it?.specialRangeTemplateName,
                },
              }
            : suggestedTemplate(suggested, it?.date)),
        };
      },
      {}
    );
  };

  useDeepEffect(() => {
    if (!isEmpty(dataFilteredByMonth)) {
      const templates = (withSuggested) =>
        dataFilteredByMonth.reduce(
          (result, doc) => ({ ...result, [`${doc.employeeId}_${doc.office}`]: processTemplates(doc, withSuggested) }),
          {}
        );
      const withSuggested = templates(true);
      const withoutSuggested = templates(false);

      dispatch(setInitialSelectedTemplates(withSuggested));
      setAssignedTemplates(withoutSuggested);
    }
  }, [dataFilteredByMonth]);

  const onCloseHandler = () => {
    onClose();
    dispatch(fetchSelectedDoctorsSpecialSuccess([]));
    dispatch(fetchDoctorsSpecial({ ...search, ...specialRangesSearch, ...specialRangesReasonsSearch }));
    dispatch(removeAllDoctorsTemplates());

    if (successAssign) {
      clearSelectedRows();
    }
  };

  const isSelectDisabled = (date) => {
    const isNoSameMonth = !getIsSameMonth(rangeValue?.value ?? startDate, date);
    const isEarlierThanToday = !getIsSameOrLaterThanToday(date);

    return some([isNoSameMonth, isEarlierThanToday], Boolean);
  };

  const weekAmount = getRemainingWeeksInMonth(rangeValue?.value || startDate);

  const onAssign = () => {
    const payload = Object.entries(selectedTemplates as bulkAssignTemplateDto).map(([id, week]) => {
      const [employeeId, office] = id.split('_');
      return {
        specialRangesTemplateDto: Object.entries(week as specialRangesTemplateDto)
          .filter(([, item]) => !isNil(item.key))
          .map(([date, data]) => {
            return {
              date,
              description: data.description,
              templateId: data.key,
              specialRangesReasons: data.specialRangesReasons || [],
            };
          }),
        userOfficeDtoList: {
          employeeId,
          office,
        },
      };
    });

    dispatch(bulkAssignTemplateSpecial({ payload, currentMonthDate: rangeValue.value }));
  };

  const isDisabledAssign = () => {
    return (
      isEqual(selectedTemplates, assignedTemplates) ||
      isNil(selectedTemplates) ||
      isEmpty(selectedTemplates) ||
      !Object.entries(selectedTemplates).some(([_, date]) => !isEmpty(date))
    );
  };

  const getPanelName = (item) => {
    const title = `${item.employeeId} ${item.name} ${item.office} ${item.officeName}`;
    return warning[`${item.employeeId}_${item.office}`] ? (
      <>
        <StyledWarningIcon alt='info' src={'./icons/info-gray.svg'} /> {title}
      </>
    ) : (
      title
    );
  };

  return (
    <StyledDrawer
      placement='right'
      visible={isDrawerVisible}
      width={1050}
      closeIcon={<img src={'./icons/close-blue.svg'} alt='close-icon' />}
      className='pace-drawer-panel'
      title={`Bulk Assignment - ${
        isAllSelected ? total - length(selectedKeys) : length(selectedKeys) || total
      } employees selected`}
      closable={true}
      onClose={onCloseHandler}
    >
      <div className='subheader bulk'>
        <StyledMonthSelect
          options={filteredMonthRanges}
          suffixIcon={<StyledCalendar src={'./icons/calendar.svg'} alt='calendar' />}
          value={rangeValue}
          onChange={(key, option) => setRangeValue(option)}
          dropdownStyle={stylesMonthSelect}
        />
        <div>
          <StyledRangePicker
            allowEmpty={[false, false]}
            format={['YYYY-MM-DD']}
            separator='-'
            onCalendarChange={(_, info) => {
              setPreApplyDateRange(info);
              setIsApplyButtonDisabled(false);
            }}
            disabledDate={(date) => !getIsSameOrLaterThanToday(date)}
            value={[
              preApplyDateRange[0] ? getMomentDate(preApplyDateRange[0]) : null,
              preApplyDateRange[1] ? getMomentDate(preApplyDateRange[1]) : null,
            ]}
          />
          <Button type='primary' disabled={isApplyButtonDisabled} onClick={() => setDateRange(preApplyDateRange)}>
            Apply
          </Button>
        </div>
        <div>
          <StyledResetButton type={'ghost'} onClick={() => dispatch(clearAllDoctorsTemplates())}>
            Clear All
          </StyledResetButton>
          <StyledAssignButton type={'primary'} onClick={onAssign} disabled={isDisabledAssign()}>
            Assign
          </StyledAssignButton>
        </div>
      </div>
      <Spin spinning={doctorLoading || assignInProgress}>
        {dataFilteredByMonth.length === 0 ? (
          <StyledEmpty />
        ) : (
          <Collapse>
            {dataFilteredByMonth.map((item: any) => (
              <Panel header={getPanelName(item)} key={`${item.employeeId}-${item.office}`}>
                {item.weeks.length === 0 ? (
                  <Empty />
                ) : (
                  item.weeks.map((week, idx) => {
                    if (idx >= weekAmount) {
                      return null;
                    }
                    return (
                      <StyledWeekWrapper single={false} key={idx} isFirst={idx === 0}>
                        <WeekSingleOfficeGrid
                          office={item.office}
                          employeeId={item.employeeId}
                          week={week}
                          weekIdx={idx}
                          isCellDisabled={isSelectDisabled}
                          rangeValue={rangeValue}
                        />
                      </StyledWeekWrapper>
                    );
                  })
                )}
              </Panel>
            ))}
          </Collapse>
        )}
      </Spin>
    </StyledDrawer>
  );
};
