import { call, put } from 'redux-saga/effects';
import { api, showErrorNotification, showSuccessNotification } from '@med-fe/util';
import { exportReportFileFailed, exportReportFileSuccess } from './reports-actions';
import { ReportName } from './models/report-name.enum';

const reportUrlMap = {
  [ReportName.SlotCount]: '/report/templateSlotCount',
  [ReportName.ODAssignments]: '/report/rangeZero',
  [ReportName.SpecialAssignments]: '/report/specialRangesAssigment',
  [ReportName.AveragePaceByOD]: '/report/averagePaceByOD',
  [ReportName.ChangePattern]: '/report/changePattern',
};

export function* exportReportStartWorker({ payload }): Generator {
  const { fileName } = payload;

  try {
    const url = reportUrlMap[fileName];

    if (url) {
      yield call(api.downloadFile, url, payload);
      yield put(exportReportFileSuccess(fileName));
      showSuccessNotification('File successfully generated');
    }
  } catch (e) {
    yield put(exportReportFileFailed(fileName));
    showErrorNotification();
  }
}
