import React from 'react';
import { HourPatternCell } from '../cell-components/HourPatternCell';
import { ExceptionCell } from '../cell-components/ExceptionCell';

export const no_special_ranges_columns = [
  {
    title: 'Employee name',
    dataIndex: 'employeeName',
    key: 'doctor',
    width: 140,
  },
  {
    title: 'Exception',
    dataIndex: 'exceptionData',
    key: 'exceptionData',
    width: 100,
    align: 'center',
    render: (exceptionData) => <ExceptionCell exceptionData={exceptionData} />,
  },
  {
    title: 'Employee id',
    dataIndex: 'employeeId',
    key: 'id',
    width: 140,
  },

  {
    title: 'Office number',
    dataIndex: 'office',
    key: 'office',
    width: 140,
  },
  {
    title: 'Office name',
    dataIndex: 'officeName',
    key: 'officeName',
    width: 140,
  },
  {
    title: 'Office HOO',
    dataIndex: 'officeHoursOfOperation',
    key: 'officeHours',
    width: 150,
    render: (pattern) => <HourPatternCell pattern={pattern} />,
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason',
    width: 300,
  },
  {
    ellipsis: true,
  }, // empty column, workaround to have column width fixed
];
