import React from 'react';
import { WeekPatternCell } from '../cell-components/WeekPatternCell';
import { HourPatternCell } from '../cell-components/HourPatternCell';
import { ExceptionCell } from '../cell-components/ExceptionCell';

export const unmappedOds_columns = [
  {
    title: 'Employee name',
    dataIndex: 'employeeName',
    key: 'doctor',
    width: 140,
  },
  {
    title: 'Employee id',
    dataIndex: 'employeeId',
    key: 'id',
    width: 140,
  },
  {
    title: 'Employee week pattern',
    dataIndex: 'employeeWeekPattern',
    key: 'week',
    width: 260,
    render: (pattern) => <WeekPatternCell pattern={pattern} />,
  },
  {
    title: 'Employee hour pattern',
    dataIndex: 'employeeHourPattern',
    key: 'hour',
    width: 210,
    render: (pattern) => <HourPatternCell pattern={pattern} />,
  },
  {
    title: 'Office number',
    dataIndex: 'office',
    key: 'office',
    width: 140,
  },
  {
    title: 'Office name',
    dataIndex: 'officeName',
    key: 'officeName',
    width: 140,
  },
  {
    title: 'Office HOO',
    dataIndex: 'officeHoursOfOperation',
    key: 'officeHours',
    width: 150,
    render: (pattern) => <HourPatternCell pattern={pattern} />,
  },
  {
    ellipsis: true,
  }, // empty column, workaround to have column width fixed
];

export const exceptionColumn = {
  title: 'Exception',
  dataIndex: 'exceptionData',
  key: 'exceptionData',
  width: 100,
  align: 'center',
  render: (exceptionData) => <ExceptionCell exceptionData={exceptionData} />,
};
